<template>
    <CContainer fluid>
        <CToaster position='top-right' :autohide='1500'>
            <template v-for='(toast, index) in toasts'>
                <CToast :key="'toast' + toast.text + index" :show='true'
                    :header="toast.header === '' ? null : toast.header">
                    {{ toast.text }}
                </CToast>
            </template>
        </CToaster>
        <CRow>
            <CCol class='col-12'>
                <CCard>
                    <CRow class='mt-3 ml-3 mr-3 justify-content-between'>
                        <CCol class='col-12 col-xl-3 col-md-6 mb-1 justify-content-end'>
                            <CButton @click='showAddModal()' block color='success'>abonelik ekle</CButton>

                        </CCol>
                    </CRow>
                    <CCardHeader>
                        <strong>Abonelik Planları</strong>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol class='col-6'>
                                <CInput label='Arama: ' :horizontal="{ label: 'w-auto', input: 'w-100' }"
                                    v-debounce:600ms='changeFilterValue' />
                            </CCol>
                        </CRow>
                        <CDataTable class='mb-0 table-outline' hover :items='tableItems' :fields='tableFields'
                            head-color='light' :loading='isListLoading' :items-per-page='itemsPerPage'>
                            <template #no-items-view>
                                <CRow>
                                    <CCol class='col-12 font-3xl text-center'>
                                        <CIcon name='cil-x' />
                                        Veri Bulunamadı
                                    </CCol>
                                </CRow>
                            </template>
                            <td slot='id' slot-scope='{item}'>
                                <div>{{ item.id }}</div>
                                <div class='small text-muted'>
                                </div>
                            </td>

                            <td slot='price' slot-scope='{item}'>
                                <div>{{ item.price }}</div>
                                <div class='small text-muted'>
                                </div>
                            </td>

                            <td slot='dayOptionId' slot-scope='{item}'>
                                <div>{{ item.dayOptionId }}</div>
                                <div class='small text-muted'>
                                </div>
                            </td>
                            <td slot='minutesPerDayOptionId' slot-scope='{item}'>
                                <div>{{ item.minutesPerDayOptionId }}</div>
                                <div class='small text-muted'>
                                </div>
                            </td>
                            <td slot="packageDurationOptionId" slot-scope='{item}'>
                                <div>{{ item.packageDurationOptionId }}</div>
                                <div class='small text-muted'>
                                </div>
                            </td>

                            <td slot='manage' slot-scope={item}>
                                <!-- <CButton @click='showEditModal(item.id)' color='success' class='mr-2 mb-1'>
                                    <CIcon name='cil-pencil' />
                                </CButton> -->
                                <CButton @click='showAlertDelete(item.id)' color='danger mb-1 ml-1'>
                                    <CIcon name='cil-x' />
                                </CButton>
                            </td>
                        </CDataTable>
                        <CPagination v-if='totalPages !== 0' :pages='totalPages' :active-page='currentPage'
                            :activePage.sync='currentPage' align='center' class='mt-3'>
                        </CPagination>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>

        <!-- New Subscription Modal -->

        <CModal title='Abonelik ekle' color='Info' :show.sync='newSubscriptionModal'>
            <CContainer fluid>
                <!-- <CRow>
                    <CCol class='col-12'>
                        <CSelect label="haftanın günleri" :value.sync="dayOptionId" :options="dayOptions"
                            placeholder="" />
                    </CCol>
                </CRow> -->
                <CRow>
                    <CCol class="col-lg-12 col-md-12 col-12">

                        <label>Paket Seç</label>
                        <Select2 v-model="packageDurationOptionId" :options="PackageDurationOptions" name="Paket Seç"
                            placeholder="Paket Seç" @select="onChange($event)" />
                    </CCol>
                </CRow>
                <!-- <CRow>
                    <CCol class='col-12'>
                        <CSelect label="günde dakika" :value.sync="minutesPerDayOptionId" :options="minuteOptions"
                            placeholder="" />
                    </CCol>
                </CRow>
                <CRow>
                    <CCol class='col-12'>
                        <CSelect label="paket süresi" :value.sync="packageDurationOptionId" :options="packageDurations"
                            placeholder="" />
                    </CCol>
                </CRow> -->
                <CRow>
                    <CCol class='col-12'>
                        <label>Referans</label>
                        <Select2 label="Referans" :options="referrals" v-model="referralId" name="Paket Seç"
                            placeholder="Paket Seç" @select="onChangeReferrals($event)" />
                        <!-- <CSelect label="Referans" :value.sync="referralId" :options="referrals" placeholder="" /> -->
                    </CCol>
                </CRow>
                <CRow>
                    <CCol class='col-12 mt-1'>
                        <CInput placeholder='fiyat' type='number' disabled description='ex. fiyat' label='fiyat'
                            v-model="price">
                            <template #prepend-content>
                                <CIcon name='cil-font' />
                            </template>
                        </CInput>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol class='col-12 mt-1'>
                        <CInput placeholder='Iyzico Plan ID' type='text' description='ex. Iyzico Plan ID' readonly
                            label='Iyzico Plan ID' v-model="iyzicoPlanId">
                            <template #prepend-content>
                                <CIcon name='cil-font' />
                            </template>
                        </CInput>
                    </CCol>
                </CRow>
            </CContainer>
            <template v-slot:footer>
                <CContainer fluid>
                    <CRow class='d-flex justify-content-between'>
                        <CCol class='col-6'>
                            <CButton @click='newSubscriptionModal = false' block color='dark'>Vazgeç</CButton>
                        </CCol>
                        <CCol class='col-6'>
                            <CButton block color='info' @click='addSubscription' :loading='isLoading'>Değişiklikleri
                                Kaydet
                            </CButton>
                        </CCol>
                    </CRow>
                </CContainer>
            </template>
        </CModal>

        <!-- Edit Subscription Modal -->

        <CModal title='Abonelik ekle' color='Info' :show.sync='editSubscriptionModal'>
            <CContainer fluid>
                <CRow>
                    <CCol class="col-lg-12 col-md-12 col-12">

                        <label>Paket Seç</label>
                        <Select2 v-model="packageDurationOptionId" :options="PackageDurationOptions" name="Paket Seç"
                            placeholder="Paket Seç" @select="onChange($event)" />
                    </CCol>
                </CRow>
                <!-- <CRow>
                    <CCol class='col-12'>
                        <CSelect label="günde dakika" :value.sync="minutesPerDayOptionId" :options="minuteOptions"
                            placeholder="" />
                    </CCol>
                </CRow>-->
                <!-- <CRow>
                    <CCol class='col-12'>
                        <CSelect label="paket süresi" :value.sync="packageDurationOptionId" :options="packageDurations"
                            placeholder="" />
                    </CCol>
                </CRow>  -->
                <CRow>
                    <CCol class='col-12'>
                        <label>Referans</label>
                        <Select2 label="Referans" :options="referrals" v-model="referralId" name="Paket Seç"
                            placeholder="Paket Seç" @select="onChangeReferrals($event)" />
                        <!-- <CSelect label="Referans" :value.sync="referralId" :options="referrals" placeholder="" /> -->
                    </CCol>
                </CRow>
                <CRow>
                    <CCol class='col-12 mt-1'>
                        <CInput placeholder='fiyat' disabled type='number' description='ex. fiyat' label='fiyat'
                            v-model="price">
                            <template #prepend-content>
                                <CIcon name='cil-font' />
                            </template>
                        </CInput>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol class='col-12 mt-1'>
                        <CInput placeholder='Iyzico Plan ID' type='text' description='ex. Iyzico Plan ID' readonly
                            label='Iyzico Plan ID' v-model="iyzicoPlanId">
                            <template #prepend-content>
                                <CIcon name='cil-font' />
                            </template>
                        </CInput>
                    </CCol>
                </CRow>
            </CContainer>
            <template v-slot:footer>
                <CContainer fluid>
                    <CRow class='d-flex justify-content-between'>
                        <CCol class='col-6'>
                            <CButton @click='editSubscriptionModal = false' block color='dark'>Vazgeç</CButton>
                        </CCol>
                        <CCol class='col-6'>
                            <CButton block color='info' @click='updateSubscription' :loading='isLoading'>Değişiklikleri
                                Kaydet
                            </CButton>
                        </CCol>
                    </CRow>
                </CContainer>
            </template>
        </CModal>
    </CContainer>
</template>

<script>
import { IyzicoSubscription } from '@/services/api-service'
import { ReferralAdministration } from '@/services/api-service'
import VueLoadingButton from 'vue-loading-button'
import { v4 as uuidv4 } from 'uuid';

import Select2 from 'vue3-select2-component';
export default {
    name: 'Subscriptions',
    Vcomponents: {
        VueLoadingButton
    },
    components: { Select2 },
    mounted() {
        this.loadSubscriptionList()
        // this.loadDayOptions()
        // this.loadMinuteOptions()
        // this.loadPackageDurations()
        // this.loadReferrals()
    },
    watch: {
        filterValue() {
            this.loadSubscriptionList()
        },
        currentPage() {
            this.loadSubscriptionList()
        },
    },
    data() {
        return {
            filterValue: '',
            currentPage: 1,
            referralDicsount: 0,
            itemsPerPage: 10,
            totalPages: 1,
            isStyled: true,
            currentItem: '',

            id: '',
            price: 0,
            dayOptionId: '',
            dayOptions: [
                { value: '', label: 'seçme' }
            ],
            minutesPerDayOptionId: '',
            PackageDurationOptionsID: '',
            minuteOptions: [
                { value: '', label: 'seçme' }
            ],
            PackageDurationOptions: [
                { id: '', text: 'seçme', value: '', dayOptionId: null, days: null, description: '', mints: null, minuteOptionId: null, totalDiscounted: null }
            ],
            packageDurationOptionId: '',
            packageDurations: [
                { value: '', label: 'seçme' }
            ],
            referralId: 0,
            referrals: [
                { id: 0, text: 'seçme', discount: 0 }
            ],
            iyzicoPlanId: '',

            isListLoading: false,
            isLoading: false,
            editSubscriptionModal: false,
            newSubscriptionModal: false,
            toasts: [],
            tableItems: [],
            tableFields: [
                { key: 'id', label: 'ID' },
                { key: 'iyzicoPlanId', label: 'Iyzico Plan Kimliği' },
                { key: 'dayOption', label: 'haftanın günleri' },
                { key: 'minutesPerDayOption', label: 'Günde Dakika' },
                { key: 'packageDurationOption', label: 'Paket Süresi' },
                { key: 'price', label: 'Fiyat' },
                { key: 'referralCode', label: 'Sevk kimliği' },
                { key: 'manage', label: 'üstesinden gelmek' },
            ],
        }
    },
    computed: {
    },
    methods: {
        changeFilterValue(newValue) {
            this.filterValue = newValue;
        },
        onChangeReferrals({ id, text, discount }) {
            this.referralId = id;
            this.referralDicsount = discount;
            this.price = (this.totalDiscountedPrice - ((this.totalDiscountedPrice / 100) * discount))
        },
        onChange({ id, text, value, dayOptionId, days, description, mints, minuteOptionId, totalDiscounted }) {
            console.log(id, text, value, dayOptionId, days, description, mints, minuteOptionId, totalDiscounted) 
            this.referralDicsount = null           
           this.loadReferrals()
            this.dayOptionId = dayOptionId
            this.packageDurationOptionId = id
            this.minutesPerDayOptionId = minuteOptionId
            this.totalDiscountedPrice = totalDiscounted
            this.price = totalDiscounted
            if (this.referralDicsount) {
                this.price = (this.totalDiscountedPrice - ((this.totalDiscountedPrice / 100) * this.referralDicsount))
            }

        },
        async showAddModal() {
            
        this.loadDropDownPackageDurationOptions()
            this.id = '';
            this.price = 0;
            this.dayOptionId = '';
            this.minutesPerDayOptionId = '';
            this.packageDurationOptionId = '';
            this.referralId = null;
            this.iyzicoPlanId = '';
            const newID = uuidv4();
            this.iyzicoPlanId = newID;
            this.newSubscriptionModal = !this.newSubscriptionModal
        },
        async showEditModal(id) {
            
        this.loadDropDownPackageDurationOptions()
            this.currentItem = this.tableItems.find(x => x.id === id)

            this.isListLoading = true;
            const result = await IyzicoSubscription.getDetailsByIdSubscriptionPlan(this.currentItem?.id)
            console.log(result);

            this.id = result.data.id;
            this.price = result.data.price;
            this.dayOptionId = result.data.dayOptionId;
            this.minutesPerDayOptionId = result.data.minutesPerDayOptionId;
            this.packageDurationOptionId = result.data.packageDurationOptionId;
            this.referralId = result.data.referralId;
            this.iyzicoPlanId = result.data.iyzicoPlanId;

            this.isListLoading = false;
            console.log(this.currentItem)
            this.editSubscriptionModal = !this.editSubscriptionModal
        },
        showAlert() {
            this.$swal({
                title: 'Emin misiniz?',
                text: 'Yaptığınız İşlem Geri Alınamayacaktır!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Evet, Sil',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$swal.fire(
                        'Silindi!',
                        'İşlem Başarıyla Gerçekleştirildi!',
                        'success',
                    )
                }
            })
        },
        showAlertDelete(id) {
            this.$swal({
                title: 'Emin misiniz?',
                text: 'Yaptığınız İşlem Geri Alınamayacaktır!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Evet, Sil',
                cancelButtonText: 'Vazgeç',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const resultDelete = await IyzicoSubscription.deleteByIdSubscriptionPlan(id);
                    console.log("result", resultDelete)
                    if (resultDelete.data == 'Done') {
                        this.loadDropDownPackageDurationOptions();
                        this.tableItems = this.tableItems.filter(r => r.id !== id)
                        await this.$swal.fire(
                            'Silindi!',
                            'İşlem Başarıyla Gerçekleştirildi!',
                            'success',
                        )
                    } else {
                        await this.$swal.fire(
                            'Silinmiş Hata!',
                            'Bu paket zaten Öğrenci ile abone',
                            'error',
                        )
                    }
                }
            })
        },
        showToast: function (text = 'İşlem Başarıyla Onaylandı', header = 'Bilgi') {
            this.toasts.push({ text, header })
        },

        deleteToast: function (text = 'İşlem Başarıyla Onaylandı', header = 'Bilgi') {
            this.toasts.push({ text, header })
        },
        async addSubscription() {
            if (this.referralId === "0") {
                this.referralId = null
            }
            if (this.price && this.dayOptionId && this.packageDurationOptionId && this.iyzicoPlanId) {

                this.isLoading = true
                try {
                    const result = await IyzicoSubscription.createSubscription({
                        price: this.price,
                        dayOptionId: this.dayOptionId,
                        minutesPerDayOptionId: this.minutesPerDayOptionId,
                        packageDurationOptionId: this.packageDurationOptionId,
                        referralId: this.referralId,
                        iyzicoPlanId: this.iyzicoPlanId,
                    })
                    console.log(result);
                    console.log(result.data.id);
                    console.log(result.data.code);
                    if (result.data.id == 0) {
                        this.$toast.error(result.data.message)
                        this.price = 0;
                        this.dayOptionId = '';
                        this.minutesPerDayOptionId = 0;
                        this.packageDurationOptionId = 0;
                        this.referralId = 0;
                        this.iyzicoPlanId = 0;

                        this.isLoading = false;
                    } else {

                        this.loadSubscriptionList();
                        this.loadDropDownPackageDurationOptions();
                        this.$toast.success('Yeni Abonelik Başarıyla Eklendi!')

                        this.price = 0;
                        this.dayOptionId = '';
                        this.minutesPerDayOptionId = 0;
                        this.packageDurationOptionId = 0;
                        this.referralId = 0;
                        this.iyzicoPlanId = 0;

                        this.isLoading = false;
                        this.newSubscriptionModal = false;
                    }
                }
                catch (e) {
                    throw new e
                }
            }
        },
        async updateSubscription() {
            if (this.referralId === "0") {
                this.referralId = null
            }
            if (this.price && this.dayOptionId && this.packageDurationOptionId && this.iyzicoPlanId) {
                this.isLoading = true
                try {
                    this.isListLoading = true;

                    const result = await IyzicoSubscription.updateSubscription({
                        id: this.id,
                        price: this.price,
                        dayOptionId: this.dayOptionId,
                        minutesPerDayOptionId: this.minutesPerDayOptionId,
                        packageDurationOptionId: this.packageDurationOptionId,
                        referralId: this.referralId,
                        iyzicoPlanId: this.iyzicoPlanId,
                    })
                    console.log(result);
                    if (result.data.id == 0) {
                        this.$toast.error(result.data.message)
                        this.price = 0;
                        this.dayOptionId = '';
                        this.minutesPerDayOptionId = 0;
                        this.packageDurationOptionId = 0;
                        this.referralId = 0;
                        this.iyzicoPlanId = 0;

                        this.isLoading = false;
                    } else {
                    this.loadSubscriptionList();
                    this.loadDropDownPackageDurationOptions();
                    this.$toast.success('Abonelik Başarıyla Güncellendi!')

                    this.price = 0;
                    this.dayOptionId = '';
                    this.minutesPerDayOptionId = 0;
                    this.packageDurationOptionId = '';
                    this.referralId = '';
                    this.iyzicoPlanId = 0;

                    this.isLoading = false
                    this.editSubscriptionModal = false;
                    }
                }
                catch (e) {
                    throw new e
                }
            }
        },
        async loadSubscriptionList() {
            this.isListLoading = true;
            const result = await IyzicoSubscription.listSubscriptionPlan(this.currentPage, this.itemsPerPage, this.filterValue)
            console.log(result);

            this.tableItems = result.data.iyzicoSubscriptionList

            console.log(this.tableItems)

            this.totalPages = result.data.pagination.totalPages
            this.currentPage = result.data.pagination.currentPage
            this.itemsPerPage = result.data.pagination.recordsPerPage

            this.isListLoading = false;
        },
        async loadDayOptions() {
            const result = await IyzicoSubscription.getDropDownSubscriptionDayOptions()
            console.log(result);
            if (result.data) {
                result.data.forEach(d => {
                    this.dayOptions.push({
                        label: d.value,
                        value: d.id
                    })
                });
            }
        },
        async loadDropDownPackageDurationOptions() {
            this.PackageDurationOptions = [];
            const result = await IyzicoSubscription.getDropDownPackageDurationOptions()
            console.log(result);
            if (result.data) {
                this.loadReferrals()
                result.data.forEach(d => {
                    this.PackageDurationOptions.push({
                        text: d.description,
                        value: d.value,
                        id: d.id,
                        dayOptionId: d.dayOptionId,
                        days: d.days,
                        description: d.description,
                        mints: d.mints,
                        minuteOptionId: d.minuteOptionId,
                        totalDiscounted: d.totalDiscounted,
                    })
                });
            }
        },
        async loadMinuteOptions() {
            const result = await IyzicoSubscription.getDropDownSubscriptionMinutesPerDayOptions()
            console.log(result);
            if (result.data) {
                result.data.forEach(d => {
                    this.minuteOptions.push({
                        label: d.value,
                        value: d.id
                    })
                });
            }
        },
        async loadPackageDurations() {
            const result = await IyzicoSubscription.getDropDownPackageDurationOptions()
            console.log(result);
            if (result.data) {
                result.data.forEach(d => {
                    this.packageDurations.push({
                        label: d.value,
                        value: d.id
                    })
                });
            }
        },
        async loadReferrals() {
            this.referrals = []
            const result = await ReferralAdministration.getDropDownReferral()
            console.log(result);
            if (result.data) {
                console.log('this.referrals', this.referrals)
                result.data.forEach(d => {
                    this.referrals.push({
                        id: d.id,
                        text: d.value + ' (' + d.discount + '%)',
                        discount: d.discount
                    })
                });
            }
        },
    },
} 
</script>
